import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { tags, categories } from "../../data/tags";

class ExperienceBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
        };
    }

    handleFilterChange = (event) => {
        this.setState({
            selected: event.target.value
        })
    };

    toggleSelectAll = (event) => {
        event.preventDefault();

        const selected = this.state.selected === categories.all ? null : categories.all;

        this.setState({
            selected
        })
    };

    selectedTags() {
        const selected = this.state.selected;

        return tags.filter((item) => {
            if (selected === categories.all) return true;

            if (selected === null) return item.default === true;

            return item.categories.includes(selected);
        });
    }

    render() {
        const selectedTags = this.selectedTags().map((tag) => {
            const value = [];

            for (let i = 0; i < tag.value; i++) {
                value.push(<div className="level__indicator" key={i}>&nbsp;</div>);
            }

            return (
                <div key={tag.name} className="tag">
                    {tag.name}

                    <div className="tag__level" aria-label={tag.value}>
                        {value}
                    </div>
                </div>
            )
        });

        const selectorLabels = Object.keys(categories).map((index) => {
            const isSelected = this.state.selected === categories[index];
            const category = categories[index];

            return (
                <label htmlFor={ index } className={ isSelected ? "is--selected" : '' } key={ index }>
                    <input type="radio" id={ index }
                           name="experienceFilter" value={ category }
                           checked={ isSelected }
                           onChange={ this.handleFilterChange }
                    />

                    <FontAwesomeIcon icon={ isSelected ? faCheckSquare : faSquare }/>
                    { category }
                </label>
            )
        });

        const toggleLink = this.state.selected === categories.all ? (
            <button type="button" className="button--link" onClick={ this.toggleSelectAll }>
                <FontAwesomeIcon icon={ faChevronUp }/>
                Piilota kaikki
            </button>
        ) : (
            <button type="button" className="button--link" onClick={ this.toggleSelectAll }>
                <FontAwesomeIcon icon={ faChevronDown }/>
                Näytä kaikki
            </button>
        );

        return (
            <div className="block" id="experience" data-color="one">
                <div className="block__content">
                    <h2>Osaaminen</h2>

                    <p>Olen tehnyt verkkosivuja ja painotöitä vuodesta 2009, pääasiassa freelance- ja vapaaehtoistyönä. Näiden yhteydessä olen toiminut niin serverivastaavana kuin SEO-optimoijana, graafisena suunnittelijana että full stack -kehittäjänä. Sydäntäni lähellä ovat työt, jotka tuovat ihmisiä yhteen tai jotka edistävät ajattelua ja taiteita; luovuus ja itseilmaisu ovat minulle tärkeitä arvoja, ja ne näkyvät myös tavassani työskennellä.</p>

                    <p>Opiskelen tällä hetkellä päätoimisesti Helsingin yliopistossa hioakseni niitä taitoja, jotka olen itseoppineena vuosien myötä saanut. Jos tarvitset verkkosivuja yrityksellesi, yhdistyksellesi tai itsellesi, ole yhteydessä! Teen mielelläni tilaa kalenteriini keikkaluontoisille projekteille.</p>

                    <p><code>All services are also available in English.</code> <code>Jag förstår väl också svenska!</code></p>

                    <section className="experience__filter">
                        <div className="filter__container">
                            { selectorLabels }
                        </div>

                        <div className="tags">
                            { selectedTags }
                        </div>

                        <p className="align--center">
                            { toggleLink }
                        </p>
                    </section>
                </div>
            </div>
        )
    }
}

export default ExperienceBlock;