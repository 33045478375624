import React from "react";

function ProjectsBlock() {
    return (
        <div className="block" id="projects" data-color="four">
            <div className="block__content">
                <h2>Työkokemus ja henkilökohtaiset projektit</h2>

                <p>Olen työskennellyt erään <code>suuren verkkokaupan front end -ohjelmoijana</code> X-Telnet -yrityksen kautta, vuosi kokoaikaisesti ja sen jälkeen freelance-keikkapohjalla opintojen ohessa. Käyttämiimme työkaluihin kuuluvat mm. <code>Visual Studio</code>, <code>Git</code>, <code>Atlassian-alusta (JIRA, Confluence)</code>. Työskentely tapahtuu tiimeissä osana isoa yritysorganisaatiota, mikä on ollut erityisen opettavaista ryhmätyöskentelyn kannalta.</p>

                <p>Olen toiminut webmasterina mm. Limes ry:lle ja Suomen tieteis- ja fantasiakirjoittajat ry:lle. Näiden yhteydessä olen perehtynyt <code>Wordpressiin</code> ja <code>WooCommerce-alustaan</code> sekä verkkomaksuintegraatioihin. Yhdistystoiminnan myötä olen tottunut kuuntelemaan, mitä ongelmia asiakas pyrkii sivuilla ratkaisemaan ja toiminaan rajallisten resurssien puitteissa. Helppokäyttöisyys ja päivitettävyys ovat minulle tärkeitä, ja pyrin jättämään järjestelmän siistimmäksi kuin minä sen löysin.</p>

                <p>Olen tehnyt töitä myös useille muille pienyrityksille ja yhteisöille. Näitä ovat olleet mm.</p>

                <ul>
                    <li>Papan paja (sipoolainen autokorjaamo)</li>
                    <li>Embassy of Silence (metallibändi)</li>
                    <li>Suomen rooli- ja miniatyyripelaajat (valtakunnallinen yhdistys)</li>
                    <li>Vaeltelija (roolipeliyhteisö, joka sisälsi mm. foorumin ja chatin)</li>
                    <li>Uskonnolliset yhteisöt (lehdet, julisteet, flyerit)</li>
                </ul>

                <p>Ylläpidin vuosia myös itse rakennettua chatohjelmaa erään verkkoyhteisön käyttöön ennen siirtymistä Discord-keskustelualustalle. Käytetyt teknologiat: <code>AngularJS (Javascript)</code>, <code>Laravel (PHP)</code>, <code>SASS (CSS)</code> ja <code>HTML 5</code>. Lähdekoodi ei ole kopiosuojan vuoksi julkisesti saatavilla, mutta lähetän sen mielelläni kysyttäessä aiheesta kiinnostuneille.</p>

                <p>Minulla on myös kirjoittamista koskeva blogi, <a href="https://www.kirjailija.blog" target="_blank" rel="noopener noreferrer">Kirjailijan elämää</a>. Blogissa puhun omista luovista projekteistani ja haastattelen kirja-alan ammattilaisia kirjailijan ammattiin liittyen.</p>
            </div>
        </div>
    )
}

export default ProjectsBlock;