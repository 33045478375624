import React from "react";

class FreetimeBlock extends React.Component {
    displayLightbox = (event) => {
        const href = event.target.src;
        const lightbox = document.getElementById("lightbox");
        const image = document.getElementById("lightboxImage");

        const delay = 1000;

        image.src = href;
        lightbox.style.opacity = 0;
        lightbox.style.display = "block";

        for (let opacity = 0; opacity <= 1; opacity = opacity + 0.1) {
            setTimeout(() => {
                lightbox.style.opacity = opacity;
            }, delay / 10);
        }
    };

    hideLightbox = () => {
        const lightbox = document.getElementById("lightbox");

        lightbox.style.display = "none";
    };

    render() {
        return (
            <div className="block" id="freetime" data-color="three">
                <div className="image__lightbox" id="lightbox" onClick={ this.hideLightbox }>
                    <div className="image">
                        <img src="/images/snowfall.jpg" alt="Valokuva" id="lightboxImage" />
                    </div>
                </div>

                <div className="block__content">
                    <h2>Vapaa-ajan minä</h2>

                    <div className="image__gallery">
                        <div className="image">
                            <img src="/images/snowfall.jpg" onClick={ this.displayLightbox } alt="Valokuva lumimaisemasta" />
                        </div>

                        <div className="image">
                            <img src="/images/cherry_blossoms.jpg" onClick={ this.displayLightbox } alt="Valokuva kirsikankukista" />
                        </div>

                        <div className="image">
                            <img src="/images/moss.jpg" onClick={ this.displayLightbox } alt="Valokuva sammaleesta" />
                        </div>
                    </div>

                    <p>Harrastan luovaa kirjoittamista ja luontokuvausta. Olen myös innokas kirjojen keräilijä ja historianörtti. Onnelliseksi minut tekevät folkmusiikki, kahvi ja kissat &mdash; hyvää kirjallisuutta unohtamatta. Jos aikakone olisi keksitty, asuisin puolipysyvästi keskiajalla.</p>
                </div>
            </div>
        )
    }
}

export default FreetimeBlock;