import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";


class BackToTop extends React.Component {
    scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        });

        this.props.handleClick("one");
    };

    render() {
        return (
            <button className="back-to-top" onClick={this.scrollToTop}>
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
        )
    }
}

export default BackToTop;