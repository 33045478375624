import React from 'react';

function ContactForm() {
    return (
        <section className="contact contact__form">
            <form action="https://formspree.io/ami@varjohovi.net" method="post">
                <input type="hidden" name="_subject" value="Varjohovi.net yhteydenotto" />

                <p>Voit myös lähettää viestin alla olevan lomakkeen kautta.</p>

                <label htmlFor="inputName">
                    Kuka olet?
                    <input name="name" id="inputName" type="text" maxLength="100" placeholder="Kirjoita nimesi tähän" required />
                </label>

                <label htmlFor="inputEmail">
                    Sähköpostiosoitteesi
                    <input name="email" id="inputEmail" type="email" maxLength="100" placeholder="Kirjoita sähköpostiosoitteesi tähän" required />
                </label>

                <label htmlFor="inputMessage">
                    Viestisi
                    <textarea name="message" id="inputMessage" placeholder="Kirjoita viestisi tähän"></textarea>
                </label>

                <input type="text" name="_gotcha" style={{ display: "none" }} />

                <button type="submit">Ota yhteyttä</button>
            </form>
        </section>
    )
}

export default ContactForm;