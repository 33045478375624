import React from "react";
import ContactLinks from "./ContactLinks";

function ContactIcons() {
    return (
        <section className="contact contact__icons">
            <ContactLinks/>
        </section>
    )
}

export default ContactIcons;