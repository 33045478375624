export const categories = {
    all: "Kaikki",
    languages: "Koodikielet",
    web: "Web-ohjelmointi",
    servers: "Palvelinohjelmointi",
    design: "Graafinen suunnittelu",
    marketing: "Markkinointi",
    tools: "Työkalut",
};

export const tags = [
    {
        name: "HTML 5",
        value: 5,
        categories: [
            categories.languages,
            categories.web,
        ],
        default: true
    },
    {
        name: "CSS",
        value: 5,
        categories: [
            categories.languages,
            categories.web,
        ],
        default: true
    },
    {
        name: "Javascript",
        value: 4,
        categories: [
            categories.languages,
            categories.web,
        ],
        default: true
    },
    {
        name: "jQuery",
        value: 5,
        categories: [
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "SASS",
        value: 4,
        categories: [
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "LESS",
        value: 4,
        categories: [
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "React",
        value: 3,
        categories: [
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "Angular 2",
        value: 2,
        categories: [
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "PHP",
        value: 4,
        categories: [
            categories.servers,
            categories.languages,
            categories.web,
        ],
        default: true
    },
    {
        name: "Laravel (PHP)",
        value: 4,
        categories: [
            categories.servers,
            categories.languages,
            categories.web,
        ],
    },
    {
        name: "Java",
        value: 2,
        categories: [
            categories.languages,
            categories.servers,
        ],
    },
    {
        name: "C#",
        value: 1,
        categories: [
            categories.languages,
            categories.servers,
        ],
    },
    {
        name: "SQL / mySQL",
        value: 4,
        categories: [
            categories.languages,
            categories.servers,
        ],
    },
    {
        name: "Apache-palvelimet",
        value: 1,
        categories: [
            categories.web,
            categories.servers,
        ],
    },
    {
        name: "Git",
        categories: [
            categories.tools,
            categories.servers,
        ],
        value: 3
    },
    {
        name: "Graafinen suunnittelu",
        value: 4,
        categories: [
            categories.design,
            categories.marketing,
        ],
        default: true
    },
    {
        name: "Responsiivinen suunnittelu",
        value: 5,
        categories: [
            categories.web,
            categories.design,
        ],
    },
    {
        name: "UX-suunnittelu",
        value: 3,
        categories: [
            categories.design,
            categories.web,
        ],
    },
    {
        name: "Taitto- ja painotyöt",
        value: 4,
        categories: [
            categories.design,
            categories.marketing,
        ],
        default: true
    },
    {
        name: "Copywriting",
        value: 3,
        categories: [
            categories.design,
            categories.marketing,
        ],
        default: true
    },
    {
        name: "SEO / Hakukoneoptimointi",
        value: 4,
        categories: [
            categories.web,
            categories.marketing,
        ],
        default: true
    },
    {
        name: "Google Analytics",
        value: 4,
        categories: [
            categories.tools,
            categories.web,
            categories.marketing,
        ],
    },
    {
        name: "Google Tag Manager",
        value: 3,
        categories: [
            categories.tools,
            categories.web,
            categories.marketing,
        ],
    },
    {
        name: "Wordpress",
        value: 4,
        categories: [
            categories.web,
        ],
        default: true
    },
    {
        name: "Blogger",
        value: 5,
        categories: [
            categories.web,
        ],
    },
    {
        name: "Photoshop",
        value: 3,
        categories: [
            categories.tools,
            categories.design,
        ],
    },
    {
        name: "Illustrator",
        value: 2,
        categories: [
            categories.tools,
            categories.design,
        ],
    },
    {
        name: "InDesign",
        value: 2,
        categories: [
            categories.tools,
            categories.design,
        ],
    },
    {
        name: "PhpStorm (IDE)",
        value: 4,
        categories: [
            categories.tools,
        ],
    },
    {
        name: "Visual Studio (IDE)",
        value: 2,
        categories: [
            categories.tools,
        ],
    },
];
