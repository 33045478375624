import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLinkedin, faGithubSquare, faTwitterSquare} from '@fortawesome/free-brands-svg-icons'
import {faEnvelopeSquare} from "@fortawesome/free-solid-svg-icons";

function ContactLinks() {
    return (
        <div className="links">
            <span className="link">
                <a href="https://www.linkedin.com/in/ami-surakka/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={ faLinkedin } />

                    <span>LinkedIn</span>
                </a>
            </span>

            <span className="link">
                <a href="https://github.com/siipis" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={ faGithubSquare } />

                    <span>GitHub</span>
                </a>
            </span>

            <span className="link">
                <a href="mailto:ami@varjohovi.net" rel="noopener noreferrer noindex">
                    <FontAwesomeIcon icon={ faEnvelopeSquare } />

                    <span>Sähköposti</span>
                </a>
            </span>

            <span className="link">
                <a href="https://twitter.com/Varjohovi" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={ faTwitterSquare } />

                    <span>Twitter</span>
                </a>
            </span>
        </div>
    )
}

export default ContactLinks;