import React from 'react';
import './assets/app.scss';
import Contact from "./sections/Contact";
import ContactIcons from "./sections/ContactIcons";
import ExperienceBlock from "./sections/blocks/ExperienceBlock";
import EducationBlock from "./sections/blocks/EducationBlock";
import FreetimeBlock from "./sections/blocks/FreetimeBlock";
import ProjectsBlock from "./sections/blocks/ProjectsBlock";
import Bookmarks from "./sections/Bookmarks";
import BackToTop from "./sections/BackToTop";

window.updateInterval = null;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: null,
      body: null,
      scrollPos: 0,
      isScrolling: false,
      preventAnimation: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);

    this.setState({
      elements: document.querySelectorAll("[data-color]"),
      body: document.getElementsByTagName("body")[0],
      scrollPos: window.scrollY,
    });
  }

  handleScroll = () => {
    if (this.state.preventAnimation) return;

    const self = this;
    const body = this.state.body;
    const elements = this.state.elements;
    const lastScrollY = this.state.scrollPos;
    const scrollY = window.scrollY;

    const scrollingUp = scrollY <= lastScrollY;
    const scrollThreshold = 0.35; // percent

    let className = "background--one";

    elements.forEach(element => {
      const visiblePixels = scrollY + window.innerHeight - element.offsetTop;
      const percentVisible = visiblePixels / window.innerHeight;

      if (scrollingUp) {
        if (percentVisible > scrollThreshold) {
          className = "background--" + element.getAttribute("data-color");
        }
      } else {
        if (percentVisible > (1 - scrollThreshold)) {
          className = "background--" + element.getAttribute("data-color");
        }
      }
    });

    clearInterval(window.updateInterval);

    window.updateInterval = window.setTimeout(() => {
      body.className = className;
      self.setState({
        isScrolling: false
      });
    }, 30);

    this.setState({
      scrollPos: window.scrollY,
      isScrolling: true,
    });
  };

  handleScrollerClick = (color) => {
    const self = this;
    const body = this.state.body;

    this.setState({
      preventAnimation: true
    });

    window.setTimeout(() => {
      body.className = "background--" + color;
    }, 40);

    // Make sure scroll has finished before unlocking the animation.
    window.updateInterval = window.setTimeout(() => {
      self.setState({
        preventAnimation: false
      })
    }, 500);
  };

  render() {
    return (
        <div className="content">
          <Bookmarks handleClick={ this.handleScrollerClick }/>

          <header>
            <ContactIcons/>

            <div className="header">
              <img src="/images/a_letter.png" alt="Kirjain A" />

              <h1><span id="given-name">Ami</span> <span id="last-name">Surakka</span></h1>
            </div>

            <div className="title">
              Web designer, writer, blogger
            </div>
          </header>

          <section className="content__blocks">
            <ExperienceBlock/>

            <EducationBlock/>

            <FreetimeBlock/>

            <ProjectsBlock/>
          </section>

          <Contact/>

          <BackToTop handleClick={ this.handleScrollerClick } />
        </div>
    )
  };
}

export default App;