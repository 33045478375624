import React from "react";

function EducationBlock() {
    return (
        <div className="block" id="education" data-color="two">
            <div className="block__content">
                <h2>Koulutus</h2>

                <p>Opiskelen tietojenkäsittelytiedettä Helsingin yliopistossa, sivuaineena kotimainen kirjallisuus. Sitä ennen olen opiskellut Haaga-Heliassa (tietojenkäsittelytiede), Linnankosken lukiossa, ja taidekoulu Inveonissa (graafinen suunnittelu). Kävin peruskoulun ruotsiksi, eli pystyn tarvittaessa työskentelemään kummallakin kielellä.</p>

                <div className="info__block">
                    <div className="image">
                        <img src="/images/hy_logo.png" alt="Ottamani valokuva" />
                    </div>

                    <div className="info__text">
                        <h3>Käynnissä oleva koulutukseni</h3>
                        <p><strong>Tietokenkäsittelytieteen kandidaatti (yht. 180 op)</strong></p>
                        <p>Matematiikan sivuaine (25 op)</p>
                        <p>Kotimaisen kirjallisuuden sivuaine (25 op)</p>
                        <p><code>Arvioitu valmistumisvuosi: 2021</code></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EducationBlock;