import React from "react";

class Bookmarks extends React.Component {
    render() {
        return (
            <div className="bookmarks">
                <a href="#experience" onClick={ () => this.props.handleClick("one") }>Osaaminen</a>
                <a href="#education" onClick={ () => this.props.handleClick("two") }>Koulutus</a>
                <a href="#freetime" onClick={ () => this.props.handleClick("three") }>Harrastukset</a>
                <a href="#projects" onClick={ () => this.props.handleClick("four") }>Projektit</a>
                <a href="#contact" onClick={ () => this.props.handleClick("five") }>Yhteys</a>
            </div>
        )
    }
}

export default Bookmarks;