import React from "react";
import ContactLinks from "./ContactLinks";
import ContactForm from "./ContactForm";

function Contact() {
    return (
        <footer id="contact" data-color="five">
            <h2>Mistä minut löytää</h2>

            <section className="contact contact__links">
                <ContactLinks/>
            </section>

            <ContactForm/>
        </footer>
    )
}

export default Contact;